import React from 'react';
import Typography from '@mui/material/Typography';
import { CompanyProfileCell } from '../components/cells';
import { formatYearMonthDay } from '../helpers';
import LazyActionDrawerCell from '../components/cells/LazyActionDrawerCell';
import LazyActionCell from '../components/cells/LazyActionCell';
import DrawerHeader from '../components/drawer/DrawerHeader';
import CollectionActions from '../components/drawer/CollectionActions';
import DateWithDaysDiffCell from '../components/dataGridCells/DateWithDaysDiffCell';

const useAmortizationScheduleColumns = () => [
  {
    id: 'id',
    field: 'id',
    headerName: 'ID',
    type: 'string',
    sortable: true,
    filterable: false,
    width: 120,
    renderCell: (params) => (
      <Typography
        variant="body1"
      >
        {params.row.id}
      </Typography>
    ),
  },
  {
    id: 'masterEntity_Name',
    field: 'masterEntity_Name',
    headerName: 'Cliente',
    type: 'string',
    sortable: true,
    filterable: false,
    flex: 1,
    renderCell: (params) => (
      <CompanyProfileCell masterEntity={params.row.masterEntity} />
    ),
  },
  {
    id: 'interestRate',
    field: 'interestRate',
    headerName: 'Tasa',
    type: 'string',
    sortable: false,
    filterable: false,
    width: 90,
    renderCell: (params) => (
      <Typography variant="body1">
        {params.row.interestRate}
      </Typography>
    ),
  },
  {
    id: 'defaultRate',
    field: 'defaultRate',
    headerName: 'Mora',
    type: 'string',
    sortable: false,
    filterable: false,
    width: 60,
    renderCell: (params) => (
      <Typography variant="body1">
        {params.row.defaultRate}
      </Typography>
    ),
  },
  {
    id: 'contentType_Model',
    field: 'contentType_Model',
    headerName: 'Producto',
    type: 'string',
    sortable: true,
    filterable: false,
    width: 60,
    renderCell: (params) => {
      const model = params.row.contentType?.model;
      let product;
      switch (model) {
        case 'invoice':
          product = 'Factoring';
          break;
        case 'confirming':
          product = 'Confirming';
          break;
        case 'advancepayment':
          product = 'Adelanto';
          break;
        case 'purchaseorder':
          product = 'Ordering';
          break;
        default:
          product = '-';
      }
      return (
        <Typography variant="body1">
          {product}
        </Typography>
      );
    },
  },
  {
    id: 'createdAt',
    field: 'createdAt',
    headerName: 'Fecha de creación',
    type: 'string',
    sortable: false,
    filterable: false,
    width: 90,
    renderCell: (params) => (
      <Typography variant="body1">
        {formatYearMonthDay(params.row.createdAt)}
      </Typography>
    ),
  },
  {
    field: 'expirationDate',
    headerName: 'Fecha de Vencimiento',
    type: 'string',
    sortable: true,
    filterable: false,
    minWidth: 150,
    renderCell: (params) => (
      <DateWithDaysDiffCell date={params.row.expirationDate} useColors />
    ),
  },
  {
    field: 'lastAction',
    headerName: 'Última gestión',
    type: 'string',
    sortable: false,
    filterable: false,
    width: 130,
    flex: 1,
    useAdditionalQueries: true,
    queryField: 'actionsDrawer',
    renderCell: (params) => (
      <LazyActionCell
        rowId={params.row.id}
        queryKey="actionsDrawer"
        additionalQueriesResults={params.additionalQueriesResults}
        queryName="amortizationSchedules"
      />
    ),
  },
  {
    field: 'actionsDrawer',
    headerName: 'Gestiones',
    type: 'string',
    sortable: false,
    filterable: false,
    maxWidth: 90,
    flex: 1,
    useAdditionalQueries: true,
    queryField: 'actionsDrawer',
    renderCell: (params) => (
      <LazyActionDrawerCell
        rowId={params.row.id}
        name={`${params.row.masterEntity.name} - ID Plan: ${params.row.id}`}
        queryKey="actionsDrawer"
        additionalQueriesResults={params.additionalQueriesResults}
        headerComponent={DrawerHeader}
        contentComponent={CollectionActions}
        queryName="amortizationSchedules"
      />
    ),
  },
];

export default useAmortizationScheduleColumns;
